<template>
  <div class="blog-detail wp-page">
    <Breadcrumb :items="breadcrumb" />
    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <recipe-detail v-if="article" :article="article" />
        </v-col>
        <v-col cols="12" md="4">
          <article-card-list
            v-if="relatedRecipes"
            title="Ricette in evidenza"
            :articleList="relatedRecipes"
          />
          <article-list
            v-if="relatedRecipes"
            title="News in evidenza"
            :articleList="relatedNews"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import article from "~/mixins/article";
import clickHandler from "~/mixins/clickHandler";
import RecipeDetail from "@/components/article/ArticleDetail.vue";
import cmService from "~/service/mksCmService";
import ArticleList from "@/components/article/ArticleList.vue";
import ArticleCardList from "@/components/article/ArticleCardList.vue";

export default {
  mixins: [article, clickHandler],
  components: {
    Breadcrumb,
    RecipeDetail,
    ArticleList,
    ArticleCardList
  },
  data() {
    return {
      article: null,
      key: 0,
      relatedRecipes: [],
      relatedNews: []
    };
  },

  methods: {
    async loadRelatedRecipes() {
      let content = await cmService.searchArticle({
        parent_article_type_id: 3,
        page_size: 3,
        sort: "random",
        page: 1
      });
      this.relatedRecipes = content?.data?.articles;
    },
    async loadRelatedNews() {
      let content = await cmService.searchArticle({
        parent_article_type_id: 2,
        page_size: 3,
        sort: "random",
        page: 1
      });
      this.relatedNews = content?.data?.articles;
    }
  },
  created() {
    // this.reload(this.$route.params.pageName);
    this.article = this.$route.params.article;
    this.loadRelatedNews();
    this.loadRelatedRecipes();
  },
  watch: {
    "$route.params.pageName": function(pageName) {
      this.reload(pageName);
    }
  }
};
</script>
